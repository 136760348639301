.site-switcher {
  display: flex;
  flex-wrap: wrap;
}

.site-switcher__dropdown-container {
  max-width: 100%;
  display: inline-flex;
}

.site-switcher-select.site-switcher__first-dropdown {
  .site-switcher-select__value-container {
    padding-left: 0;
  }
  .site-switcher-select__single-value,
  .site-switcher-select__placeholder {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.5px;
  }
}

.site-switcher-select.site-switcher__second-dropdown {
  .site-switcher-select__value-container {
    padding-left: 0;
  }
  .site-switcher-select__single-value,
  .site-switcher-select__placeholder {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.5px;
  }
}

.site-switcher-select.site-switcher-select--fetching {
  .site-switcher-select__value-container {
    min-width: 0;
  }

  .site-switcher-select__placeholder {
    display: none;
  }
}

// If searchable, the menu should appear below the
// input, so the user can see what they are typing
.site-switcher-select.site-switcher-select--searchable {
  .site-switcher-select__menu {
    top: 36px;
  }
}

.site-switcher-select {
  display: inline-flex;
  max-width: 100%;

  .site-switcher-select__control {
    max-width: 100%;
    flex-wrap: nowrap;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    min-height: 36px;
  }

  .site-switcher-select__value-container {
    max-width: 100%;
    padding: 0;
    flex-wrap: nowrap;
    flex: 0 0 auto;
    cursor: pointer;
    min-width: 90px;
    display: flex;
  }

  .site-switcher-select__value-container--has-value {
    min-width: 0;
  }

  .site-switcher-select__placeholder {
    padding: 0 10px 0 0;
  }

  .site-switcher-select__single-value {
    font-size: 13px;
    color: $voltus-text-color;
    position: relative;
    top: 0;
    transform: none;
    max-width: 100%;
  }

  .site-switcher-select__menu {
    top: 0;
    margin: 0;
    border-radius: 3px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px map-get($grays, 10);
    z-index: $z-index-10;
    left: 0;
    width: auto;
  }

  .site-switcher-select__menu-list {
    padding: 0;
  }

  .site-switcher-select__option:last-child {
    border-bottom: 0;
  }

  .site-switcher__virtualized-option-wrapper {
    &:last-child {
      border-bottom: 0;
    }
  }

  .site-switcher-select__option--is-focused {
    background: $voltus-form-select-bg;

    &:active {
      background: $voltus-form-select-active;
    }
  }

  .site-switcher-select__dropdown-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding: 0;
  }
}

.site-switcher-select__icon {
  cursor: pointer;
}

.site-switcher__dropdown-icon {
  font-size: 10px;
  font-weight: bold;
}

.site-switcher__dropdown-icon--rotating {
  animation: rotate 1s linear infinite;
}

.site-switcher__selected-option-icon {
  position: absolute;
  top: 6px;
  left: -16px;
}

.site-switcher__option {
  position: relative;
  margin-left: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.site-switcher__dropdown-separator {
  min-width: 3px;
  max-width: 3px;
  height: 29px;
  transform: translate(0, 4px) rotate(15deg);
  border-radius: 1.5px;
  background-color: map_get($grays, 20);
  margin-left: 11px;
  margin-right: 11px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
