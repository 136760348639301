@mixin med-screen {
  @media (min-width: #{$screen-med-min}) {
    @content;
  }
}

@mixin large-screen {
  @media (min-width: #{$screen-large-min}) {
    @content;
  }
}

@mixin contentWrapperStyles {
  width: 94%;
  flex-grow: 1;
  max-width: $screen-med-min;
  margin: 0 auto;

  @include med-screen {
    max-width: $max-content-width;
  }
}
